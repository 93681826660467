import styled from 'styled-components';

export const Subtitle = styled.h2`
    text-align: left;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 900;
    line-height: 1.1;
`;
